import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Landing from './pages/landing/Landing';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Mission from './pages/mission/Mission'
import Board from './pages/board/Board'
import Sponsors from './pages/sponsors/Sponsors'
import Season from './pages/season/Season'
import Gallery from './pages/gallery/Gallery'
import Donations from './pages/donations/Donations'
import Volunteer from './pages/volunteer/Volunteer'
import Auditions from './pages/auditions/Auditions';
import Levels from './pages/levels/Levels'
import Bts from './pages/bts/Bts'
import Escuela from './pages/escuela/Escuela'
import Forums from './pages/forums/Forums'
import Contact from './pages/contact/Contact'
import EventDetails from './components/eventDetails/EventDetails';

import {english} from './info/english'
import {spanish} from './info/spanish'
import { useLanguageContext } from './hooks/useLanguageContext'


function App() {
  const { language } = useLanguageContext()

  let languageInfo;
  if (language === 'english') {
      languageInfo = english;
  } else if (language === 'spanish') {
      languageInfo = spanish;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/board" element={<Board />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/season" element={<Season />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/donations" element={<Donations />} />
          <Route path="/volunteer" element={<Volunteer />} />
          <Route path="/auditions" element={<Auditions />} />
          <Route path="/levels" element={<Levels />} />
          <Route path="/bts" element={<Bts />} />
          <Route path="/escuela" element={<Escuela />} />
          <Route path="/forums" element={<Forums />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/events/:id" element={<EventDetails/>}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
