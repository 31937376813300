import Banner from '../../components/banner/Banner'
import './bts.css'

const Bts = () => {
    return (
        <div className="bts__container">
            <Banner
                span="Ópera Cultura "
                after="Community"
                />
            <h2>Behind the Scenes with Ópera Cultura</h2>
            <h3>Mi Camino</h3>
            <p>This playlist includes short clips of behind-the-scenes videos created during the development and production of Mi Camino: The Pandemic Stories of farmworkers – a new song cycle opera by composer Héctor Armienta, which premiered online in June 2021.</p>
            <p>Composer/Artistic Director Hector Armienta and Cecilia Violetta López, Soprano are discussing the aria, “Somos Familia,” one of the featured pieces in #MiCamino. One of the challenges during COVID-19 is the need to use virtual instruments for large orchestrations. Tempo nuances, like rallentando, are particularly challenging and we were trying to figure out how to accomplish this.</p>
            <img src="" />
            <p>A recording session with Cecilia Violetta López, Soprano. Premiere dates coming soon for #MiCamino – The Pandemic Stories of Farmworkers by Hector Armienta. </p>
            <img src="" />
            <h3>Cuentos de Los Campos</h3>
            <p>Stories of farmworkers shared as part of #MiCamino, a new song cycle opera by composer Héctor Armienta.</p>

            <p>Stephanie, a student, shares her experience of working in the fields. Stephanie’s story is one of several that will be shared in #MiCamino #CuentosdelosCampos.</p>
            <img src="" />
            <p>“It’s hard, but I would say I go back for it because I’ve gained that love, that love for the people.”—SJSU student</p>
            <p>“Erica” talks about her experience working with other piscaderos in the fields in this excerpt of #CuentosdelosCampos. One of the stories collected for Hector Armienta’s new song cycle opera #MiCamino.</p>
            <img src="" />
            <p>#CuentosdelosCampos – A clip from Hector Armienta’s interview with “Mateo.” One of the stories that has been incorporated into his new song cycle opera #MiCamino.</p>
            <img src="" />
            <p>#CuentosdelosCampos: a clip from Hector Armienta’s interview with “Rosa” for the new song cycle opera #MiCamino: The Pandemic Stories of Farmworkers.</p>
            <img src="" />
        </div>
    )
}

export default Bts