import './levels.css'

const Levels = () => {
    return (
        <div>
            Levels of support page!
        </div>
    )
}

export default Levels