import './footer.css'
import { useState, useEffect } from 'react'

import aztec from '../../img/AztecFade.svg'
import mayan from '../../img/MayanSculptureFade.svg'
import frida from '../../img/FridaKahloFade.svg'
import day from '../../img/DayOfTheDeadFade.svg'
import chichen from '../../img/ChichenItzaFade.svg'
import aztecH from '../../img/AztecHeaddressFade.svg'

import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import { FaTwitter } from "react-icons/fa6";

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'

import { useLanguageContext } from '../../hooks/useLanguageContext'

import DOMPurify from 'dompurify'

const Footer = () => {

    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }

    
    const [footerInfo, setFooterInfo] = useState(null)
    const [error, setError] = useState(null)
    const [mailAddress, setMailAddress] = useState('')
    const [contactDescription, setContactDescription] = useState('')

    const year = new Date().getFullYear();

    // Get all text for Footer component
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/footer')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setFooterInfo(json)
            }
            }
        fetchData()     
        }, [])

    // For debugging, handling inner HTML, and images
    useEffect(()=> {
        console.log(footerInfo)
        if (footerInfo) {
            const cleanMailAddress = DOMPurify.sanitize(footerInfo[0].mailAddress, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setMailAddress(cleanMailAddress)
    
            const cleanContactDescription = footerInfo[0].contactDescription;
            setContactDescription(cleanContactDescription)
        }
    }, [footerInfo])


    return (
        <footer className="footer__container">
            <div className="contact__flex">
                <div className="contact__left">
                    <h3>{footerInfo && footerInfo[0].mailTitle}</h3>
                    <p dangerouslySetInnerHTML={{__html: mailAddress}}></p>
                </div>
                <div className='contact__center'>
                    <h3>{footerInfo && footerInfo[0].contactTitle}</h3>
                    <p dangerouslySetInnerHTML={{__html: contactDescription}}></p>
                </div>
                <div className="contact__right">
                    <h3>{footerInfo && footerInfo[0].socialTitle}</h3>
                    <div className="contact__social-flex">
                        <a href="https://www.youtube.com/@operacultura8841" target="_blank" rel="noopener noreferrer" className="contact__youtube"><FaYoutube /></a>
                        <a href="https://www.facebook.com/OperaCultura" target="_blank" rel="noopener noreferrer" className="contact__facebook" ><FaFacebook /></a>
                        <a href="https://twitter.com/opera_cultura" target="_blank" rel="noopener noreferrer" className="contact__x"><FaTwitter /></a>
                        <a href="https://www.instagram.com/opera_cultura.latinx/" target="_blank" rel="noopener noreferrer" className="contact__instagram"><FaInstagram /></a>
                    </div>
                </div>
            </div>
            <div className="footer__belt">
                <a href="https://en.wikipedia.org/wiki/Maya_stelae" target="_blank" rel="noopener noreferrer" title="Mayan Sculpture">
                    <img src={mayan} alt="Mayan Sculpture" />
                </a>
                <a href="https://en.wikipedia.org/wiki/Chichen_Itza" target="_blank" rel="noopener noreferrer" title="Chichen Itza">
                    <img src={chichen} alt="Chichen Itza" className="footer__belt-md" />
                </a>
                <a href="https://en.wikipedia.org/wiki/Day_of_the_Dead" target="_blank" rel="noopener noreferrer" title="Day of the Dead">
                    <img src={day} alt="Day of the Dead" className="footer__belt-sm" />
                </a>
                <a href="https://en.wikipedia.org/wiki/Montezuma%27s_headdress" target="_blank" rel="noopener noreferrer" title="Aztec Headdress">
                    <img src={aztecH} alt="Aztec Headdress" />
                </a>
                <a href="https://en.wikipedia.org/wiki/Aztecs" target="_blank" rel="noopener noreferrer" title="Aztec Warrior">
                    <img src={aztec} alt="Aztec Warrior" className="footer__belt-sm" />
                </a>
                <a href="https://en.wikipedia.org/wiki/Frida_Kahlo" target="_blank" rel="noopener noreferrer" title="Frida Kahlo">
                    <img src={frida} alt="Frida Kahlo" className="footer__belt-sm" />
                </a>
                </div>
            <div className="attribution">
                <p>&copy; Ópera Cultura {year}. Website built by <a href="https://www.mattsdevprojects.com" target="_blank" rel="noopener noreferrer">Matt's Dev Projects</a>.</p>
            </div>
        </footer>
    )
}

export default Footer