import './board.css'
import { useState, useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import Banner from '../../components/banner/Banner'

import hector from '../../img/hector.jpg'
import sandra from '../../img/sandra.jpg'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

import DOMPurify from 'dompurify'

const Board = () => {
    const [sandraBio, setSandraBio] = useState(false);
    const [hectorBio, setHectorBio] = useState(false);

    const [showBio, setShowBio] = useState(false)
    const [clickedItem, setClickedItem] = useState('');
    
    // Grab info online
    const [boardInfo, setBoardInfo] = useState(null)
    const [staffInfo, setStaffInfo] = useState(null)
    const [error, setError] = useState(null)

    const [staffBio, setStaffBio] = useState(null)

    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }

    // Get all text for Board page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/board')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setBoardInfo(json)
            }
        }
            fetchData()     
    }, [])

    // Get all text for Staff components
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/staff')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setStaffInfo(json)
            }
        }
            fetchData()     
    }, [])

    // For debugging, handling inner HTML, and images
    useEffect(()=> {
        console.log(boardInfo)
        if (staffInfo) {
            const cleanStaffBio = staffInfo.map(item => DOMPurify.sanitize(item.staffBio, {ALLOWED_ATTR: ['href', 'rel', 'target']}));
            setStaffBio(cleanStaffBio);
        }
    }, [staffInfo])


    useEffect(() => {
        if (sandraBio || hectorBio) {
            document.body.style.overflow = 'hidden'; // Disable scrolling when the popup is active
        } else {
            document.body.style.overflow = ''; // Enable scrolling when the popup is closed
        }

        return () => {
            document.body.style.overflow = ''; // Restore default scrolling behavior when the component unmounts
        };
    }, [sandraBio, hectorBio]);

    const handleClick = (index) => {
        setClickedItem(index);
        console.log(index)
        setShowBio(true)
      };
      
    return (
        <div className="board__container">
            <Banner
                title={boardInfo && boardInfo[0].bannerText}
                image={boardInfo && boardInfo[0].bannerImage}
                />
            <h2 className="board__title">{boardInfo && boardInfo[0].staffTitle}</h2>
            <div className="board__flex">
            {staffInfo && staffInfo.map((item, index) => (
                    <div className="board__left">
                    <img 
                        src={item.staffImage} 
                        alt={item.staffImageAltText} 
                        className="board__image-left"
                        onClick={() => handleClick(index)}
                        name="hector" />
                    <h2>{item.staffName}</h2>
                    <h3>{item.staffPosition}</h3>

                    {showBio && clickedItem === index && (
                        <div className="board__popup">
                            <div className='board__popup-content'>
                            <IoMdClose 
                                className="board__popup-close"
                                onClick={() => setShowBio(false)}
                            />
                            <h2>{item.staffName}</h2>
                            <h3>{item.staffPosition}</h3>
                            <p dangerouslySetInnerHTML={{__html: staffBio[index]}}></p>
                            </div>
                        </div>
                        )}

                </div>
                ))}
            </div>
            <h2 className="board_bod-title">{boardInfo && boardInfo[0].boardTitle}</h2>
            <div className="board__board"> 
            {boardInfo && boardInfo[0].boardNames.map((name) => (
                        <h3>{name}</h3>
                    ))}
            </div>
        </div>
    )
}

export default Board