import './landing.css'
import { useState, useEffect } from 'react'
import jsonp from 'jsonp'

// Language toggle
import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

// Images and Videos
import g13 from '../../img/g13.jpg'
import logo from '../../img/logo12.png'
import video from '../../movies/boheme.mp4'; // Import the video file

// Components
import UpcomingEvents from '../../components/upcomingEvents/UpcomingEvents'
import Popup from '../../components/popup/Popup'

import DOMPurify from 'dompurify';

const Landing = () => {

    const [landingInfo, setLandingInfo] = useState(null)
    const [error, setError] = useState(null)

    const [donateTitle, setDonateTitle] = useState('')
    const [donateParagraph, setDonateParagraph] = useState('')
    
    const [loading, showLoading] = useState(false)
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [emptyFields, setEmptyFields] = useState([])
    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text for Landing page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/landing')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setLandingInfo(json)
            }
        }
            fetchData()     
    }, [])

         // For debugging, handling inner HTML, and images
         useEffect(()=> {
            console.log(landingInfo)
            if (landingInfo) {
                const cleanDonateTitle = DOMPurify.sanitize(landingInfo[0].donateTitle, {ALLOWED_ATTR: ['href', 'rel', 'target']})
                setDonateTitle(cleanDonateTitle)
                const cleanDonateParagraph = DOMPurify.sanitize(landingInfo[0].donateParagraph, {ALLOWED_ATTR: ['href', 'rel', 'target']})
                setDonateParagraph(cleanDonateParagraph)
            }
        }, [landingInfo])

    const redirectToLink = () => {
        window.open(`${landingInfo && landingInfo[0].donateButtonLink}`, "_blank");
    };

    

    const handleClick = () => {
        console.log(fname, lname, email)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setEmptyFields([])
        if (!fname) {
            setEmptyFields(emptyFields => [...emptyFields, 'fname']);
        }
        if (!lname) {
            setEmptyFields(emptyFields => [...emptyFields, 'lname']);
        }
        if (!email) {
            setEmptyFields(emptyFields => [...emptyFields, 'email']);
        }
        if (!fname || !lname || !email) {
            alert('Please fill in all marked fields.')
            return
        }
        const url = process.env.REACT_APP_MAIL_CHIMP;
        jsonp(`${url}&EMAIL=${email}&FNAME=${fname}&LNAME=${lname}`, { param: 'c' }, (_, data) => {
            const { msg, result } = data
            // do something with response
            alert(msg);
        });

    };
    
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY >= 100) {
            showLoading(true);
            window.removeEventListener('scroll', handleScroll);
          }
        };
      
        window.addEventListener('scroll', handleScroll);
      
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const closeLoading = () => {
        showLoading(false);
    }


    return (
        <div className="landing__container">
            <div className='landing__hero'>
                {/* <video
                    src={video}
                    autoPlay
                    muted
                    loop
                    playsInline
                    /> */}
                <img src={g13} alt="Frida Kahlo" />    
                <div className='landing__hero-content'>
                    <img className="landing__logo" src={logo} alt="logo" />
                    <h1>{landingInfo && landingInfo[0].heroTitle}</h1>
                    <h4>{landingInfo && landingInfo[0].heroDescription}</h4>
                </div>
                <Popup 
                            close={closeLoading}
                            loading={loading}
                    />
            </div>
            <UpcomingEvents />
            <div className="landing__cta-container">
                <div className='aztec-banner'></div>
                <h2 className="landing-donate__title" dangerouslySetInnerHTML={{__html: donateTitle}}></h2>
                <div className="landing__cta">
                    <p dangerouslySetInnerHTML={{__html: donateParagraph}}></p>
                    <button className='landing__cta-button' onClick={redirectToLink}>{landingInfo && landingInfo[0].donateButtonText}</button>
                </div>
                <div className='aztec-banner'></div>
            </div>
            
            <div className='landing__mailing-list'>
                <div className="landing__mailing-list-blue">

                    <h2>{landingInfo && landingInfo[0].mailTitle}</h2>
                    <div className='landing__mailing-list-flexbox'>
                        <input type="text" className={emptyFields.includes('fname') && 'error' } value={fname} onChange={(event) => setFname(event.target.value)} placeholder={landingInfo && landingInfo[0].mailFname}></input>
                        <input type="text" className={emptyFields.includes('lname') && 'error' } value={lname} onChange={(event) => setLname(event.target.value)} placeholder={landingInfo && landingInfo[0].mailLname}></input>
                        <input type="email" className={emptyFields.includes('email') && 'error' } value={email} onChange={(event) => setEmail(event.target.value)} placeholder={landingInfo && landingInfo[0].mailEmail}></input>
                        <button onClick={onSubmit}>{landingInfo && landingInfo[0].mailButton}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing;