import cantar from '../img/reyes.png'
import llorona from '../img/llorona-poster.png'
import alejandro from '../img/alejandro.webp'
import bailey from '../img/bailey.jpg'
import sanchez from '../img/sanchez.jpg'
import yerzhanov from '../img/Yerzhanov.jpg'


import mexicanHeritage from '../img/mexican-heritage-map.png'
import trinity from '../img/map-trinity.png'

export const spanish = {
    navbar: {
        about: {
            title: "Acerca",
            submenu: {
                title1: "Misión e Historia",
                title2: "Junta y Personal",
                title3: "Patrocinadores"
            }
        },
        season: {
            title: "Estación",
            submenu: {
                title1: "23 / 24 Estación",
                title2: "Galería"
            }
        },
        involved: {
            title: "Involucrarse",
            submenu: {
                title1: "Donaciones",
                title2: "Voluntario",
                title3: "Audiciones"
            }
        },
        community: {
            title: "Comunidad",
            submenu: {
                title1: "La Escuela",
                title2: "Entre Bastidores"
            }
        },
        contact: {
            title: "Contacto",
            submenu: {
                title1: "Contacto",
            }
        }
    },
    footer: {
        title1: "Dirección de Envio",
        title2: "Contacto",
        title3: "Social",
        contact1: "¿Necesita ayuda adicional?",
        contact2: "Por favor contáctenos:"
    },
    landing: {
        hero: {
            title: "Ópera Cultura",
            tagline: "Construyendo un puente para artistas y comunidades latino/x/a/é"
        },
        popup: {
            text: "¡Únase a Ópera Cultura para nuestro evento navideño, Cantar con los Reyes!",
            buttonLink: "/cantar-con-los-reyes",
            buttonText: "Entradas"
        },
        cta: {
            titleBefore: "Donar a la ",
            titleBold: "Ópera Cultura",
            titleAfter:"",
            paragraph:"Apóyanos en nuestra misión de dar voz a la comunidad latino/x/a/é a través de la ópera. Su donación nos ayuda a brindar capacitación a los jóvenes y a mostrar a artistas excepcionales en las artes escénicas.",
            buttonText:"Done Ahora",
            buttonLink:"https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU"
        },
        upcomingEvents: {
            title: "Próximos Eventos",
            option1: {
                image: cantar,
                title:"Cantar con los Reyes",
                date:"6 de Enero de 2024",
                buttonText: "Más Información",
                buttonLink: "/cantar-con-los-reyes"
            },
            option2: {
                image:llorona,
                title:"La Llorona",
                date:"22 de Junio, 23 de Junio de 2024",
                buttonText: "Más Información",
                buttonLink: "/la-llorona"
            }
        },
        mailingList: {
            title: "Únete a Nuestra Lista de Correos",
            fName: "Nombre de Pila",
            lName: "Apellido",
            email: "Correo Electrónico",
            buttonText: "Unirse",
            buttonLink: ""
        }
    },
    mission: {
        banner1: "Sobre ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        cta: "Ópera Cultura cuenta con el apoyo de nuestra comunidad para prosperar. No importa la cantidad, su apoyo es profundamente apreciado.",
        ctaButtonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        ctaButtonText: "Done Ahora",
        mission: {
            title: "Misión",
            content: "Nuestra misión es dar “VOZ” a la comunidad latino/x/a/é a través de la ópera, mientras brindamos oportunidades para que la comunidad de San José participe como creadores, estudiantes e intérpretes. Esto lo logramos presentando el trabajo del compositor Héctor Armienta y otros compositores hispanos, mostrando artistas excepcionales y capacitando a jóvenes en las artes escénicas. Al hacerlo, creamos un puente cultural entre comunidades."
        },
        history: {
            title: "Historia",
            content: "Nuestra programación en el pasado ha incluido presentar ópera latino/x/a/é, colaborar con organizaciones artísticas latino/x/a/é y brindar capacitación a jóvenes en las artes escénicas a través de la Iniciativa La Escuela. Si bien San José sigue siendo nuestro hogar artístico, también hemos presentado y copresentado en Nueva York, Washington D.C y México. Las actuaciones notables incluyen La LLorona, Bless Me Ultima y el ciclo de ópera de canciones de inmigración de Armienta, Cuentos de Peregrinacíon. En 2021 produjimos Mi Camino, una ópera cinematográfica animada contada en el mundo virtual. Basado en entrevistas en video de trabajadores agrícolas, contó la historia de trabajadores agrícolas que se han visto afectados por la pandemia de Covid 19. En 2022 produjimos la ópera Frida de Robert Rodríguez en el Teatro Herencia Mexicana – Escuela de Artes y Cultura. La ópera está basada en la vida de la artista visual mexicana Frida Kahlo. Más recientemente, produjimos la ópera Tres Minutos del compositor Nicolás Benavides."
        }
    },
    board: {
        banner1: "Sobre ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        staff: {
            title: "Personal",
            staff1: {
                title: "Héctor Armienta",
                position: "Director artístico y fundador",
                bio1: "Armienta supervisa la programación artística de la empresa. También trabaja estrechamente con la productora artística Sandra Bengochea y la junta directiva en planificación a largo plazo y la Iniciativa La Escuela.",
                bio2: "Como compositor, ha recibido encargos e interpretaciones de varias compañías de ópera, incluidas la Ópera de Fort Worth, la Ópera Southwest y la Ópera de Portland, entre otras. Su ópera más reciente, Zorro, ha sido producida en Texas, Nuevo México y próximamente en California."
            },
            staff2: {
                title: "Sandra Raquel Bengochea",
                position: "Directora ejecutiva",
                bio1: "La señora Bengochea supervisa las distintas producciones de la empresa. Trabaja en estrecha colaboración con el Sr. Armienta para identificar artistas, diseñadores y personal para cada producción.",
                bio2: "La Sra. Bengochea es una reconocida cantante de ópera y ha actuado a nivel regional. También forma parte del cuerpo docente del Departamento de Voz de la Universidad Estatal de San José y es la Coordinadora de Área del departamento."
            }
        },
        boardTitle: "Junta Directiva"
    },
    sponsors: {
        banner1: "Sobre ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        title: "Patrocinadores",
        p1: "¡Dale “Voz” a la comunidad latino/x/a/é a través de la ópera donando hoy! Su apoyo nos permite:",
        item1: "Trabajo actual sobre la comunidad latino/x/a/é",
        item2: "Ser un defensor de los artistas y creadores latino/x/a/é en el campo de la ópera",
        item3: "Proporcionar formación a jóvenes en las artes escénicas",
        item4: "Presentar talleres en la comunidad",
        p2: "Como organización sin fines de lucro, Opera Cultura depende de la generosidad de sus donaciones para sostener sus producciones. ¡Cualquier contribución es muy valorada y apreciada!",
        buttonText: "Done Ahora",
        buttonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        sponsors: "Nuestros patrocinadores"
    },
    season: {
        banner1: "",
        bannerBold: "Ópera Cultura",
        banner2: " temporada 23/24",
        donate: {
            paragraph: "¿No puedes asistir a un espectáculo pero aún quieres apoyar?",
            buttonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
            buttonText: "Done Ahora"
        },
        event1: {
            title: "Cantar con los Reyes",
            subtitle: "Evento Comunitario de Vacaciones",
            dates: ["Sábado 6 de Enero a las 14 h."],
            image: cantar,
            location: {
                googleMapsLink: "https://www.google.com/maps/dir//3151+Union+Ave,+San+Jose,+CA+95124/@37.2646706,-121.9390069,14.81z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808e34593d4d6dad:0xcf66768d074be8b2!2m2!1d-121.9325501!2d37.2660867?entry=ttu",
                mapImage: trinity,
                locationName: "Trinity Presbyterian Church",
                address1: "3151 Union Ave",
                address2: "San Jose, CA 95124"
            },
            tag: "¡Trae a la familia a nuestro evento navideño para cantar!",
            content: "Venga a celebrar la temporada el 6 de enero con música alegre y tradiciones compartidas en nuestro evento de canto navideño. El artista invitado, Omar Alejandro, barítono, dirigirá la fiesta con melodías navideñas familiares y canciones de “La Época de Oro”, que se hicieron famosas por artistas como Los Panchos, Jorge Negrete y Pedro en La Llorona de Héctor Armienta regresa a San José en junio de 2024. Visto en California y Nueva York, las producciones anteriores se agotaron para este galardonado drama musical.fante.",
            buttonLink: "https://www.tix.com/ticket-sales/operacultura/6882/event/1353852",
            buttonText: "Entradas",
            featuredArtistTitle: "Artistas Destacados",
            featuredArtists: [
                {
                name: "Omar Alejandro",
                image: alejandro,
                description: "Con su estilo de canto operístico y su galante presencia escénica, Omar Alejandro da nueva vida a los géneros mariachi y operístico. El nativo de California se está estableciendo rápidamente como uno de los cantantes solistas más versátiles de la región; interpreta clásicos de la época dorada del cine mexicano. ópera, teatro musical y todo lo demás.",
                buttonText: "Omar Alejandro",
                buttonLink: "https://www.omaralejandrorodriguez.com/"
            }]
        },
        event2: {
            title: "La Llorona",
            subtitle: "Un Drama Musical",
            dates: [
                "22 de Junio a las 19.30 h",
                "23 de Junio a las 14.00 h"
            ],
            image: llorona,
            location: {
                googleMapsLink: "https://www.google.com/maps/place/Mexican+Heritage+Plaza/@37.3496823,-121.8626666,14.78z/data=!3m1!5s0x808fcd1fae008e3b:0xf2abd411adb336f0!4m6!3m5!1s0x808fcd1e39ee0cc1:0x7a2c29050d3dcecf!8m2!3d37.3528342!4d-121.8547252!16zL20vMDUyOXd0?entry=ttu",
                mapImage: mexicanHeritage,
                locationName: "Mexican Heritage Plaza",
                address1: "1700 Alum Rock Ave",
                address2: "San Jose, CA 95116"
            },
            tag: "Redescubre el antiguo cuento de la Mujer que Llora. Compuesta y escrita por Héctor Armienta.",
            content: "La Llorona de Héctor Armienta regresa a San José en junio de 2024. Visto en California y Nueva York, las producciones anteriores han agotado las entradas para este galardonado drama musical.",
            buttonLink: "",
            buttonText: "",
            noButtonText: "¡Entradas a la venta pronto!",
            featuredArtistTitle: "Artistas Destacados",
            featuredArtists: [
                {
                name: "Stephanie Sanchez",
                image: sanchez,
                description: "En la temporada 2022/23, la dinámica mezzosoprano Stephanie Sánchez llevó su 'sonido delicioso y técnica fácil' (Classical Voice North America) a compañías como Arizona Opera, Intermountain Opera Bozeman, Opera Southwest y The Lyric Opera of Chicago, con quienes interpretó papeles en Proximity: The Walkers y Proximity: Four Portraits. Comenzó la temporada 2023/24 con la Ópera de Cincinnati, cantando Chief Officer's Wife en The Knock. Espera con ansias su debut como Die Hexe en Hänsel und Gretel con la Ópera de Kentucky, y luego debuta con la Ópera Omaha como la Frida Kahlo titular en El último sueño de Frida y Diego. En el verano de 2024, se une a West Edge Opera como Flosshilde/Fricka/Erda en Der Ring in einem Abend.",
                buttonText: "Stephanie Sanchez",
                buttonLink: "https://www.stephaniesanchezmezzosoprano.com/"
            },
            {
                name: "Allie Bailey",
                image: bailey,
                description: "",
                buttonText: "Allie Bailey",
                buttonLink: "https://www.linkedin.com/in/allie-bailey-9662b396/"
            },
            {
                name: "Temirzhan Yerzhanov",
                image: yerzhanov,
                description: "",
                buttonText: "Temirzhan Yerzhanov",
                buttonLink: "https://spotlightartistsmanagement.com/artists/temirzhan-yerzhanov/"
            },

            ]
        }
    },
    gallery: {
        banner1: "Galería ",
        bannerBold: "Ópera Cultura",
        banner2: "",
    },
    donations: {
        banner1: "Involúcrate con ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        title: "Donaciones",
        p1: "En Ópera Cultura creemos que la ópera es una forma de arte vibrante y transformadora que merece ser compartida con todos. Sin embargo, montar una actuación puede resultar bastante caro y la venta de entradas sólo cubre una parte de los costes. Es por eso que contamos con el generoso apoyo de individuos, fundaciones y corporaciones para ayudarnos a dar vida a la magia de la ópera. Al donar a Ópera Cultura, usted se convierte en una parte importante de nuestra misión de brindar experiencias operísticas excepcionales y valiosos programas educativos a nuestra comunidad de San José.",
        p2: "Juntos, hagamos avanzar la ópera y sostengamos la excelencia artística y las asociaciones comunitarias que brinda Ópera Cultura. No importa la cantidad, su apoyo es profundamente apreciado. Únase a nosotros para abrazar el poder del 'apoyo' y conviértase en parte de nuestra vibrante familia de ópera latino/x/a/é hoy.",
        buttonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        buttonText: "Done Ahora",
        taxes: "Opera Cultura es una organización sin fines de lucro 501(c)(3), EIN #46-4332937. Todas las donaciones son deducibles de impuestos."
    },
    volunteer: {
        banner1: "Involúcrate con ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        title: "¡Sé parte de la Ópera Cultura familia!",
        p1: "¡Siempre damos la bienvenida a los voluntarios para que se unan a nuestra vibrante comunidad de ópera y contribuyan a nuestras producciones! Hay muchas formas interesantes de involucrarse y marcar la diferencia, como ser acomodador, gestionar concesiones, ayudar detrás del escenario, hospitalidad, educación, etc.",
        p2: "No importa qué rol elijas, ser voluntario con nosotros es una experiencia gratificante que te permite ser parte de la magia de la ópera y contribuir a nuestra misión de brindar actuaciones excepcionales a nuestra comunidad de San José. ¡Únete a nosotros como voluntario y ayúdanos a crear momentos inolvidables juntos!",
        buttonLink: "",
        buttonText: "Voluntario"
    },
    escuela: {
        banner1: "Comunidad ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        cta: "La Escuela no puede existir sin el apoyo de nuestra comunidad. No importa la cantidad, su apoyo es profundamente apreciado.",
        ctaButtonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        ctaButtonText: "Sea un Partidario",
        title: "Iniciativa La Escuela",
        content: "Establecida en 2022, La Escuela brinda capacitación en artes escénicas a jóvenes de 16 a 22 años. Después de participar en un programa de 10 semanas, algunos de los estudiantes tienen la oportunidad de actuar en nuestras producciones teatrales principales junto a artistas profesionales. Cada estudiante recibe un estipendio por su participación. Estudiantes y padres interesados ​​por favor contáctenos en info@operacultura.org para más información.",
        buttonLink: "https://forms.gle/ysEPM8Rasd39FwHc9",
        buttonText: "Inscribirse",
        schoolYear: "Programa Escolar 2023 / 2024",
        schoolDay: "Martes",
        schoolDates: "4/16, 4/30, 5/14, 5/28",
        schoolTime: "Tiempo",
        schoolHours: "6:30pm - 8:30pm",
        schoolLocation: "Ubicación",
        schoolPlace: "Hoover Theater",
        schoolMapLink: "https://www.google.com/maps/place/Historic+Hoover+Theatre/@37.3327678,-121.9258175,17z/data=!3m1!4b1!4m6!3m5!1s0x808fcb6b1a112e3f:0x7b8a30e2b55de789!8m2!3d37.3327678!4d-121.9232426!16s%2Fg%2F1hhx3zgj1?entry=ttu",
        schoolDescription: "El programa escolar de este año será un taller combinado de música y teatro centrado en el folclore, específicamente el folclore mexicano y el cuento de 'La Llorona'.",
        schoolRegistration: "La inscripción abre el 15 de enero y cierra el 31 de marzo."

    },
    contact: {
        banner1: "Contacto ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        title: "Contacta con nosotros",
        name: "Nombre",
        email: "Correo Electrónico",
        message: "Mensaje",
        buttonLink: "",
        buttonText: "Entregar"
    },
}
