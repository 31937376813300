import './sponsors.css'
import { useState, useEffect } from 'react'
import DOMPurify from 'dompurify';

// Language toggle
import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

// Images and Icons
import sponsor_wide from '../../img/sponsors/Sponsors_wide.png'
import sponsor_normal from '../../img/sponsor11.png'
import sponsor_thin from '../../img/sponsors/Sponsors_phone.png'

import drama from '../../img/drama.svg'
import choir from '../../img/choir.svg'
import training from '../../img/training.svg'
import helping from '../../img/voice.svg'


// Components
import Banner from '../../components/banner/Banner'


const Sponsors = () => {
    const [sponsorInfo, setSponsorInfo] = useState(null)
    const [error, setError] = useState(null)
    
    const [sponsorBlurb, setSponsorBlurb] = useState('')
    const [list1, setList1] = useState('')
    const [list2, setList2] = useState('')
    const [list3, setList3] = useState('')
    const [list4, setList4] = useState('')
    const [donateBlurb, setDonateBlurb] = useState('')

    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }

    const redirectToLink = () => {
        window.open(`${sponsorInfo && sponsorInfo[0].donateButtonLink}`, "_blank");
    };



    // Get all text for Sponsors page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/sponsor')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setSponsorInfo(json)
            }
          }
        fetchData()     
      }, [])
  
      // For handling inner HTML
      useEffect(()=> {
        console.log(sponsorInfo)
        if (sponsorInfo) {
            const cleanSponsorBlurb = DOMPurify.sanitize(sponsorInfo[0].sponsorBlurb, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setSponsorBlurb(cleanSponsorBlurb)
            const cleanList1 = DOMPurify.sanitize(sponsorInfo[0].sponsorList1, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setList1(cleanList1)
            const cleanList2 = DOMPurify.sanitize(sponsorInfo[0].sponsorList2, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setList2(cleanList2)
            const cleanList3 = DOMPurify.sanitize(sponsorInfo[0].sponsorList3, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setList3(cleanList3)
            const cleanList4 = DOMPurify.sanitize(sponsorInfo[0].sponsorList4, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setList4(cleanList4)
            const cleanDonateBlurb = DOMPurify.sanitize(sponsorInfo[0].donateBlurb, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setDonateBlurb(cleanDonateBlurb)
        }
    }, [sponsorInfo])



    return (
        <div className="sponsors__container">
          <Banner
            title={sponsorInfo && sponsorInfo[0].bannerText}
            image={sponsorInfo && sponsorInfo[0].bannerImage}
            />
            <div className="sponsors__banner">
                <h2>{sponsorInfo && sponsorInfo[0].sponsorTitle}</h2>
            </div>
                <p className="sponsor__paragraph" dangerouslySetInnerHTML={{__html: sponsorBlurb}}></p>
            <div className='sponsors__new-idea'>
            <div className="sponsors__triangle-border" ></div>
                <div className="sponsors__flex">
                    <div className="sponsors__flex-item">
                        <img src={drama} alt="dramatic theater masks" className="sponsors__icon" />
                        <p dangerouslySetInnerHTML={{__html: list1}}></p>
                    </div>
                    <div className="sponsors__flex-item">
                        <img src={helping} alt="offering a helping hand" className="sponsors__icon" />
                        <p dangerouslySetInnerHTML={{__html: list2}}></p>
                    </div>
                    <div className="sponsors__flex-item">
                        <img src={choir} alt="group of singers" className="sponsors__icon" />
                        <p dangerouslySetInnerHTML={{__html: list3}}></p>
                    </div>
                    <div className="sponsors__flex-item">
                        <img src={training} alt="presenting a workshop" className='sponsors__icon' />
                        <p dangerouslySetInnerHTML={{__html: list4}}></p>
                    </div>
                </div>
                <div className="sponsors__triangle-border-bottom" ></div>
            </div>
            <p className="sponsor__paragraph" dangerouslySetInnerHTML={{__html: donateBlurb}}></p>
            <button onClick={redirectToLink}>{sponsorInfo && sponsorInfo[0].donateButtonText}</button>
            <div className="sponsors__images">
                <h2>{sponsorInfo && sponsorInfo[0].imageTitle}</h2>
                <img className="sponsors__sponsors-wide" src={sponsorInfo && sponsorInfo[0].imageCollectionLarge} alt={sponsorInfo && sponsorInfo[0].largeAltText} />
                <img className="sponsors__sponsors-normal" src={sponsorInfo && sponsorInfo[0].imageCollectionMedium} alt={sponsorInfo && sponsorInfo[0].mediumAltText} />
                <img className="sponsors__sponsors-thin" src={sponsorInfo && sponsorInfo[0].imageCollectionSmall} alt={sponsorInfo && sponsorInfo[0].smallAltText} />
            </div>
        </div>
    )
}

export default Sponsors