import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './eventAd.css'

const EventAd = (props) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`${props.buttonLink}`)
    }

    return (
        <div className='eventAd__container'>
            <img src={props.image} alt={props.altText} />
            <h2>{props.title}</h2>
            {props.date.map((item) => (
                <p key={item}>{item}</p>
            ))}
            <p><i>{props.tag}</i></p>
            <button onClick={handleClick}>{props.buttonText}</button>
        </div>
    )
}

export default EventAd