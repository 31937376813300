import './volunteer.css'
import Banner from '../../components/banner/Banner'

import v1 from '../../img/v2.jpg'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'
import { useState, useEffect, useRef } from 'react'

import DOMPurify from 'dompurify';

const Volunteer = () => {
    const [volunteerForm, showVolunteerForm] = useState(false);
    const volunteerFormRef = useRef(null);
    const { language } = useLanguageContext()

    const [volunteerInfo, setVolunteerInfo] = useState(null)
    const [error, setError] = useState(null)
    const [volunteerParagraph, setVolunteerParagraph] = useState('')
    

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text for Volunteer page
    useEffect(()=> {
      const fetchData = async () => {
          const response = await fetch('https://opera-cultura-server.onrender.com/api/volunteer')
          const json = await response.json()
          if (!response.ok) {
              setError(json.error)
          }
          if (response.ok) {
              setError(null)
              setVolunteerInfo(json)
          }
        }
      fetchData()     
    }, [])

    // For debugging, handling inner HTML, and images
    useEffect(()=> {
      console.log(volunteerInfo)
      if (volunteerInfo) {
          const cleanVolunteerParagraph = DOMPurify.sanitize(volunteerInfo[0].volunteerParagraph, {ALLOWED_ATTR: ['href', 'rel', 'target']})
          setVolunteerParagraph(cleanVolunteerParagraph)
      }
  }, [volunteerInfo])

  

    const handleClick = () => {
        if (!volunteerForm) {
          showVolunteerForm(true);
          setTimeout(() => {
            if (volunteerFormRef.current) {
              window.scrollTo({
                top: volunteerFormRef.current.offsetTop,
                behavior: 'smooth',
              });
            }
          }, 0);
        } else {
          showVolunteerForm(false);
        }
      };

    return (
        <div className="volunteer__container">
          <Banner
            title={volunteerInfo && volunteerInfo[0].bannerText}
            image={volunteerInfo && volunteerInfo[0].bannerImage}
            />
            <div className="volunteer__grid">
              <img 
                  src={volunteerInfo && volunteerInfo[0].volunteerImage} 
                  alt={volunteerInfo && volunteerInfo[0].vImageAltText}
                  className="v1-computer" />
                <div className="volunteer__content">
                    <h2>{volunteerInfo && volunteerInfo[0].volunteerTitle}</h2>
                    <img 
                      src={volunteerInfo && volunteerInfo[0].volunteerImage} 
                      alt={volunteerInfo && volunteerInfo[0].vImageAltText}
                      className="v1-phone" />
                    <p dangerouslySetInnerHTML={{__html: volunteerParagraph}}></p>
                    
                    <button onClick={handleClick}>{volunteerInfo && volunteerInfo[0].volunteerButtonText}</button>
                </div>
            </div>
            {volunteerForm && 
            <div 
                className='volunteer__form' 
                id='volunteerForm'
                ref={volunteerFormRef}
                >
                {language === "english" && <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdZT77N__ySZP3FpxtJ0DXJCcKpAAzmoXV2gJMjyEGJk567Nw/viewform?embedded=true" >Loading…</iframe>}
                {language === "spanish" && <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf1CaHd4OVdigUsw2QowJ2t2GqMTlGsa7fWkFPADFXqzndBvw/viewform?embedded=true" >Loading…</iframe>}
            </div>
            }
        </div>
    )
}


// width="640" height="1662" frameborder="0" marginheight="0" marginwidth="0"

export default Volunteer