import './forums.css'
import { useState, useEffect } from 'react'
import Banner from '../../components/banner/Banner'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

import banner2 from '../../img/banner2.jpg'

import DOMPurify from 'dompurify';


const Forums = () => {
    const [forumInfo, setForumInfo] = useState(null)
    const [forumBannerInfo, setForumBannerInfo] = useState(null)
    const [error, setError] = useState(null)
    const [forumParagraph, setForumParagraph] = useState('')

    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }

    // Get all text for Forum banner
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/forumsBanner')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setForumBannerInfo(json)
            }
        }
            fetchData()     
    }, [])

    // Get all text for Forum components
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/forums')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setForumInfo(json)
                console.log(forumInfo)
            }
        }
            fetchData()     
    }, [])

         // For debugging, handling inner HTML, and images
    useEffect(()=> {
        if (forumInfo) {
            const cleanForumParagraph = forumInfo.map(item => DOMPurify.sanitize(item.forumParagraph, {ALLOWED_ATTR: ['href', 'rel', 'target']}));
            setForumParagraph(cleanForumParagraph);
        }
    }, [forumInfo])



     const redirectToLink = (index) => {
            window.open(`${forumInfo && forumInfo[index].forumButtonLink}`, "_blank");
    };
    const debug = () => {
        console.log(forumInfo)
    }


    return (
        <div className="forums__container">
            <Banner
                title={forumBannerInfo && forumBannerInfo[0].bannerText}
                image={forumBannerInfo && forumBannerInfo[0].bannerImage}
                />
            {forumInfo &&
            
            forumInfo.map((item, index) => (
            <div className="forums__item">
                <h2>{item.forumTitle}</h2>
                <div className="forums__grid">
                    <div className="forums__content">
                        <p dangerouslySetInnerHTML={{__html: forumParagraph[index]}}></p>
                        <button onClick={() => {redirectToLink(index)}}>{item.forumButtonText}</button>
                    </div>
                    <img src={item.forumImage} alt={item.forumAltText} />
                </div>
            </div>
            ))}
        </div>
    )
}

export default Forums