import './mission.css'
import { useState, useEffect } from 'react'

import mission from '../../img/mission.jpg'
import history from '../../img/history2.jpg'
import Banner from '../../components/banner/Banner'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'
import Donate from '../../components/donate/Donate'

import DOMPurify from 'dompurify'

const Mission = () => {
    const { language } = useLanguageContext()
    const [missionInfo, setMissionInfo] = useState(null)
    const [error, setError] = useState(null)
    const [missionText, setMissionText] = useState(null)
    const [historyText, setHistoryText] = useState(null)


    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


        // Get all text for Mission page
        useEffect(()=> {
            const fetchData = async () => {
                const response = await fetch('https://opera-cultura-server.onrender.com/api/mission')
                const json = await response.json()
                if (!response.ok) {
                    setError(json.error)
                }
                if (response.ok) {
                    setError(null)
                    setMissionInfo(json)
                }
            }
                fetchData()     
        }, [])
    
        // For debugging, handling inner HTML, and images
        useEffect(()=> {
            console.log(missionInfo)
            if (missionInfo) {
                const cleanMission = DOMPurify.sanitize(missionInfo[0].missionText, {ALLOWED_ATTR: ['href', 'rel', 'target']})
                setMissionText(cleanMission)
                const cleanHistory = DOMPurify.sanitize(missionInfo[0].historyText, {ALLOWED_ATTR: ['href', 'rel', 'target']})
                setHistoryText(cleanHistory)
            
            }
        }, [missionInfo])



    return (
        <div className="mission__container">
           <Banner
                title={missionInfo && missionInfo[0].bannerText}
                image={missionInfo && missionInfo[0].bannerImage}
                />
            <Donate
                paragraph={missionInfo && missionInfo[0].donateText}
                buttonText={missionInfo && missionInfo[0].donateButtonText}
                buttonLink={missionInfo && missionInfo[0].donateButtonLink}
            />
            <div className="mission__flex">
                <div className="mission__content">
                    <h2>{missionInfo && missionInfo[0].missionTitle}</h2>
                    <p dangerouslySetInnerHTML={{__html: missionText}}></p>
                </div>
                <img src={missionInfo && missionInfo[0].missionImage} alt={missionInfo && missionInfo[0].missionAltText} />
            </div>
            <div className="mission__flex">
                <img src={missionInfo && missionInfo[0].historyImage} alt={missionInfo && missionInfo[0].historyAltText} />
                <div className="mission__content">
                    <h2>{missionInfo && missionInfo[0].historyTitle}</h2>
                    <p dangerouslySetInnerHTML={{__html: historyText}}></p>
                </div>
            </div>
        </div>
    )
}

export default Mission