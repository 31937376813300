import './loading.css'
import { IoMdClose } from 'react-icons/io'
import { FaCheck } from "react-icons/fa";
import { BarLoader } from 'react-spinners'


const Loading = (props) => {

    return (
        <div className='loading__container'>
            {props.loading ?
                <div>
                    <p>Sending email...</p>
                    <BarLoader color="#E89A84" />
                </div>
                :
                props.error ?
                <p>{props.error}</p> :
                <div>
                    <p>Your email has been sent!</p>
                    <FaCheck className="loading__check-box" color="#989A2C"/> 
                </div>
                
            }
            <IoMdClose 
                className="loading__close"
                onClick={props.close} />
        </div>
    )
}

export default Loading