import './eventDetails.css'

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

import { IoOpenOutline } from "react-icons/io5";

import DOMPurify from 'dompurify';

import Banner from '../banner/Banner';

const EventDetails = (props) => {
    const location = useLocation();
    const url = location.pathname
    console.log(url)

    const [seasonInfo, setSeasonInfo] = useState(null)
    const [eventInfo, setEventInfo] = useState(null)
    const [featuredInfo, setFeaturedInfo] = useState(null)
    const [error, setError] = useState(null)

    const [eventAddress, setEventAddress] = useState('')
    const [eventNoTicketsBlurb, setEventNoTicketsBlurb] = useState('')
    const [eventDescription, setEventDescription] = useState('')

    const [featuredDescription, setFeaturedDescription] = useState('')

    
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text from Event document
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/event')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                
                // Filter the json object based on upcomingButtonLink
                const filteredEventInfo = json.filter(item => item.upcomingButtonLink === url);
                setEventInfo(filteredEventInfo);
            }
        }
            fetchData()     
    }, [])


    // Get all text for Season page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/season')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setSeasonInfo(json)
            }
        }
            fetchData()     
    }, [])


    // Get all text from Featured document
    useEffect(()=> {
        const fetchData = async () => {
            console.log("Getting Featured info")
            const response = await fetch('https://opera-cultura-server.onrender.com/api/featured')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                // Filter the json object based on upcomingButtonLink
                console.log("Checking the ID", eventInfo[0]._id)
                const filteredFeaturedInfo = json.filter(item => item.featuredParent === eventInfo[0]._id);

                setFeaturedInfo(filteredFeaturedInfo);
            }
        }
            if (eventInfo) {
                fetchData()     
            }
    }, [eventInfo])


    // For handling inner HTML
    useEffect(()=> {
        if (eventInfo) {
            const cleanEventAddress = DOMPurify.sanitize(eventInfo[0].eventAddress, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setEventAddress(cleanEventAddress)
            const cleanEventNoTicketsBlurb = DOMPurify.sanitize(eventInfo[0].eventNoTicketsBlurb, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setEventNoTicketsBlurb(cleanEventNoTicketsBlurb)
            const cleanEventDescription = DOMPurify.sanitize(eventInfo[0].eventDescription, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setEventDescription(cleanEventDescription)
        }
    }, [eventInfo])

    // For handling inner HTML
    useEffect(()=> {
        if (featuredInfo) {
            const cleanFeaturedDescription = featuredInfo.map(item => DOMPurify.sanitize(item.featuredDescription, {ALLOWED_ATTR: ['href', 'rel', 'target']}));
            setFeaturedDescription(cleanFeaturedDescription);
        }
    }, [featuredInfo])

    return (
        <div>
            <Banner
                title={seasonInfo && seasonInfo[0].bannerText}
                image={seasonInfo && seasonInfo[0].bannerImage}
                />

            <div className="eventDetails__container">
                <div className="eventDetails__container-left">
                <iframe 
                    className="event-youtube-video"
                    width="500" 
                    height="315" 
                    src={eventInfo && eventInfo[0].eventVideo} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <h2>{eventInfo && eventInfo[0].featuredTitle}</h2>
                    {featuredInfo && featuredInfo.map((artist, index) => (
                        <div key={artist.featuredName}>
                            <h3>{artist.featuredName}</h3>
                            <img src={artist.featuredImage} alt={artist.featuredImageAltText} />
                            <p dangerouslySetInnerHTML={{__html: featuredDescription[index]}}></p>
                            <a className="event-button" href={artist.featuredButtonLink} target="_blank" rel="noopener noreferrer">
                            {artist.featuredButtonText}
                            </a>
                        </div>
                        ))}
                </div>
                <div className="eventDetails__container-right">
                    <h1>{eventInfo && eventInfo[0].eventTitle}</h1>
                    <img className="eventDetails__poster" src={eventInfo && eventInfo[0].eventImage} alt={eventInfo && eventInfo[0].eventImageAltText}></img>
                    <h3>{eventInfo && eventInfo[0].eventTitle}</h3>
                    <p className="eventDetails__subtitle">{eventInfo && eventInfo[0].eventSubtitle}</p>
                    {eventInfo && eventInfo[0].eventDayTime.map((date) => (
                        <p key={date} className='eventDetails__dates'>{date}</p>
                        ))}
                    <div className="eventDetails__location">
                        <a href={eventInfo && eventInfo[0].eventLocationLink} target="_blank" rel="noopener noreferrer" >{eventInfo && eventInfo[0].eventLocationTitle} <IoOpenOutline className="map-icon" /></a>
                        <p dangerouslySetInnerHTML={{__html: eventAddress}}></p>
                    </div>
                    <img src={eventInfo && eventInfo[0].eventMap} alt={eventInfo && eventInfo[0].eventMapAltText} className="eventDetails__location-map" />
                    {eventInfo && eventInfo[0].eventTicketsLink ? <a className="event-button" href={eventInfo[0].eventTicketsLink} target="_blank" rel="noopener noreferrer">{eventInfo[0].eventTicketsText}</a> : <p className="eventDetails__no-tickets" dangerouslySetInnerHTML={{__html: eventNoTicketsBlurb}}></p> }
                    <p dangerouslySetInnerHTML={{__html: eventDescription}}></p>
                </div>
            </div>

        </div>
    )
}

export default EventDetails