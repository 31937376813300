import './upcomingEvents.css'
import { useState, useEffect } from 'react'

import EventAd from '../eventAd/EventAd'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'


const UpcomingEvents = () => {
    const [seasonInfo, setSeasonInfo] = useState(null)
    const [eventInfo, setEventInfo] = useState(null)
    const [featuredInfo, setFeaturedInfo] = useState(null)
    const [error, setError] = useState(null)
    
    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text from Season document
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/season')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setSeasonInfo(json)
            }
        }
            fetchData()     
    }, [])

    // Get all text from Event document
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/event')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setEventInfo(json)
            }
        }
            fetchData()     
    }, [])

    // Get all text from Featured document
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/featured')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setFeaturedInfo(json)
            }
        }
            fetchData()     
    }, [])

    return (
        <div className='upcomingEvents__container'>
            <h2 className="upcomingEvents__title">{seasonInfo && seasonInfo[0].upcomingTitle}</h2>
            <div className='upcomingEvents__flexbox'>
            {eventInfo && 
                eventInfo
                .filter((event) => {
                    const startDate = new Date(event.startDate);
                    const endDate = new Date(event.endDate);
                    const today = new Date();
                    return startDate <= today && endDate >= today;
                })
                .map((eAd, index) => (
                    <EventAd 
                        image={eventInfo && eventInfo[index].eventImage}
                        altText={eventInfo && eventInfo[index].eventImageAltText}
                        title={eventInfo && eventInfo[index].eventTitle}
                        date={eventInfo && eventInfo[index].eventDayTime}
                        tag={eventInfo && eventInfo[index].upcomingBlurb}
                        buttonText={eventInfo && eventInfo[index].upcomingButtonText}
                        buttonLink={eventInfo && eventInfo[index].upcomingButtonLink}
                    />
                ))}
                {/* Render this statement if no events are upcoming */}
                {eventInfo && eventInfo.filter((event) => {
                    const startDate = new Date(event.startDate);
                    const endDate = new Date(event.endDate);
                    const today = new Date();
                    return startDate <= today && endDate >= today;
                }).length === 0 && (
                    <p id="noUpcomingEvents">No upcoming events currently.</p>
                )}
                </div>
        </div>
    )
}

export default UpcomingEvents