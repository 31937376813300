import './contact.css'
import Banner from '../../components/banner/Banner'
import emailjs from '@emailjs/browser';

import { useState, useEffect } from 'react';
import { english } from '../../info/english'
import { spanish } from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'
import Loading from '../../components/loading/Loading';


const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(null)

    const [contactInfo, setContactInfo] = useState(null)
    
    function sendEmail(e) {
        setLoading(true)
        setShowLoading(true)
        e.preventDefault();
        console.log(e.target)

        emailjs.sendForm('service_w5zw1tw', 'template_1u8hncq', e.target, 'glIU5aKO-dstGXEV2').then(res=>{
            console.log(res);
            // alert("Your email has been sent!");
            setLoading(false);
        }).catch(err=> {
            setLoading(false);
            setError(err);
        })
        e.target.reset()
    }

    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text for Contact page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/contact')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setContactInfo(json)
            }
        }
            fetchData()     
    }, [])

    // const handleClick = () => {
    //     alert("which email would you like this to send to?")
    // }

    const closeLoading = () => {
        setShowLoading(false);
        setError(null)
    }

    return (
        <div className="contact">
            <Banner
                title={contactInfo && contactInfo[0].bannerText}
                image={contactInfo && contactInfo[0].bannerImage}
                />
            {showLoading && <Loading 
                loading={loading}
                close={closeLoading}
                error={error}
            /> }
            <div className="contact__container">
                <div className="contact__box">
                    <span className="box__border"></span>
                    <form onSubmit={sendEmail}>
                        <h2 className="contact__title">{contactInfo && contactInfo[0].contactTitle}</h2>
                        <div className="box__input">
                            <input  
                                type="text" 
                                name="name" 
                                required="required" />
                            <span>{contactInfo && contactInfo[0].contactName}</span>
                            <i></i>
                        </div>
                        <div className="box__input">
                            <input  
                                type="email" 
                                name="email" 
                                required="required" />
                            <span>{contactInfo && contactInfo[0].contactEmail}</span>
                            <i></i>
                        </div>
                        <div className="box__input">
                            <textarea 
                                name="message"
                                required="required" />
                            <span>{contactInfo && contactInfo[0].contactMessage}</span>
                            <i></i>
                        </div>
                        <input className="contact__send-email" type="submit" value={contactInfo && contactInfo[0].contactButton} />
                        {/* <button onClick={handleClick}>{languageInfo.contact.buttonText}</button> */}
                    
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact