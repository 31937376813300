import './season.css'
import { useState, useEffect } from 'react'

import Banner from '../../components/banner/Banner'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

import UpcomingEvents from '../../components/upcomingEvents/UpcomingEvents'
import Donate from '../../components/donate/Donate'

const Season = () => {
    const [seasonInfo, setSeasonInfo] = useState(null)
    const [error, setError] = useState(null)

    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }

        // Get all text for Season page
        useEffect(()=> {
            const fetchData = async () => {
                const response = await fetch('https://opera-cultura-server.onrender.com/api/season')
                const json = await response.json()
                if (!response.ok) {
                    setError(json.error)
                }
                if (response.ok) {
                    setError(null)
                    setSeasonInfo(json)
                }
            }
                fetchData()     
        }, [])

    return (
        <div className="season__container">
            <Banner
                title={seasonInfo && seasonInfo[0].bannerText}
                image={seasonInfo && seasonInfo[0].bannerImage}
                />
            <Donate
                paragraph={seasonInfo && seasonInfo[0].donateBlurb}
                buttonText={seasonInfo && seasonInfo[0].donateButtonText}
                buttonLink={seasonInfo && seasonInfo[0].donateButtonLink}
            />
            <UpcomingEvents />
        </div>
    )
}

export default Season