import './gallery.css'
import { useState, useEffect } from 'react'
import Banner from '../../components/banner/Banner'
import g1 from '../../img/g26.jpg'
import g2 from '../../img/g18.jpg'
import g3 from '../../img/g3.jpg'
import g4 from '../../img/g4.jpg'
import g5 from '../../img/g5.jpg'
import g6 from '../../img/g6.png'
import g7 from '../../img/g21.jpg'
import g8 from '../../img/g8.jpg'
import g9 from '../../img/g27.jpg'
import g10 from '../../img/g10.jpg'
import g11 from '../../img/g11.jpg'
import g12 from '../../img/g12.jpg'
import g13 from '../../img/g28.jpg'
import g14 from '../../img/g24.jpg'
import g15 from '../../img/g15.jpg'
import g16 from '../../img/g16.jpg'

import frida1 from '../../img/FridaOptimized1.webp'
import frida2 from '../../img/FridaOptimize2.webp'
import frida3 from '../../img/FridaOptimized3.webp'

import v1 from '../../movies/infinite.mp4'

import { FaInstagram, FaYoutube } from 'react-icons/fa'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'


const Gallery = () => {
    const { language } = useLanguageContext()


    const [galleryInfo, setGalleryInfo] = useState(null)
    const [error, setError] = useState(null)

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }



    // Get all text for Gallery page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/gallery')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setGalleryInfo(json)
            }
        }
            fetchData()     
    }, [])


    // For debugging, handling inner HTML, and images
    useEffect(()=> {
        console.log(galleryInfo)
        if (galleryInfo) {
            // const cleanOpeningParagraph = DOMPurify.sanitize(escuelaInfo[0].openingParagraph, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            // setOpeningParagraph(cleanOpeningParagraph)
            // const cleanDatesTimes = DOMPurify.sanitize(escuelaInfo[0].datesTimes, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            // setDatesTimes(cleanDatesTimes)
            // const cleanClassDescription = DOMPurify.sanitize(escuelaInfo[0].classDescription, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            // setClassDescription(cleanClassDescription)
            // const cloudinaryBannerImage = escuelaInfo[0].bannerImage;
            // setBannerImage(cloudinaryBannerImage)
        }
    }, [galleryInfo])

    return (
        <div className="gallery__container">
            <Banner
                title={galleryInfo && galleryInfo[0].bannerText}
                image={galleryInfo && galleryInfo[0].bannerImage}
                />
            <div className="gallery__flex">
            {galleryInfo && galleryInfo[0].itemImage.map((image, index) => {
  if (image.endsWith('.mp4')) {
    return (
      <div key={index}>
        <video 
            src={image} 
            controls
            playsInline
            loading="lazy" />
        <p>{galleryInfo && galleryInfo[0].itemLabel[index]}</p>
      </div>
    );
  } else {
    return (
      <div key={index}>
        <img src={image} alt={galleryInfo && galleryInfo[0].itemLabel[index]} loading="lazy" />
        <p>{galleryInfo && galleryInfo[0].itemLabel[index]}</p>
      </div>
    );
  }
})}
                
                <div>
                    <video
                        src={v1}
                        controls
                        playsInline
                        loading="lazy"
                        />
                    <p>INFINITE working with students</p>
                </div>
                       
            </div>
            <div className="gallery__more-content">
                <p>More media can be found on our social media pages:</p>
                <div className="gallery__more-content-flex">
                    <a href="https://www.youtube.com/@operacultura8841" target="_blank" rel="noopener noreferrer" className="contact__youtube"><FaYoutube className="gallery__icon" /> YouTube</a>
                    <a href="https://www.instagram.com/opera_cultura/" target="_blank" rel="noopener noreferrer" className="contact__instagram"><FaInstagram className="gallery__icon" /> Instagram</a>
                </div>
            </div>
        </div>
    )
}

export default Gallery