import './popup.css'
import { IoMdClose } from 'react-icons/io'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'


const Popup = (props) => {
    const [landingInfo, setLandingInfo] = useState(null)
    const [error, setError] = useState(null)

    
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }



    // Get all text for Landing page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/landing')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setLandingInfo(json)
            }
        }
            fetchData()     
    }, [])

    const redirectToLink = () => {
        window.open(`${landingInfo && landingInfo[0].popupButtonLink}`, "_blank");
    };

    const handleClick = () => {
        navigate(landingInfo && landingInfo[0].popupButtonLink)
    }

    return (
        <div className={`popup__container ${props.loading && "slide-in"}`}>
            <div>
                <p>{landingInfo && landingInfo[0].popupParagraph}</p>
                <button onClick={handleClick}>{landingInfo && landingInfo[0].popupButtonText}</button> 
            </div>
            <IoMdClose 
                className="popup__close"
                onClick={props.close} />
        </div>
    )
}

export default Popup