import './navbar.css'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo2 from '../../img/Logo-water.png'
import { AiOutlineDown } from 'react-icons/ai'
import { CiMenuBurger } from 'react-icons/ci'
import { IoMdClose } from 'react-icons/io'
import { useLocation } from 'react-router-dom';

import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'

import aztec from '../../img/AztecFade.svg'
import mayan from '../../img/MayanSculptureFade.svg'
import frida from '../../img/FridaKahloFade.svg'
import day from '../../img/DayOfTheDeadFade.svg'
import chichen from '../../img/ChichenItzaFade.svg'
import aztecH from '../../img/AztecHeaddressFade.svg'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

const Navbar = () => {
  const [menu, setMenu] = useState(false)
  const [aboutMenu, setAboutMenu] = useState(false)
  const [seasonMenu, setSeasonMenu] = useState(false)
  const [involvedMenu, setInvolvedMenu] = useState(false)
  const [communityMenu, setCommunityMenu] = useState(false)
  const [contactMenu, setContactMenu] = useState(false)
  const [windowSmall, setWindowSmall] = useState(false)

  const [navbarInfo, setNavbarInfo] = useState(null)
  const [error, setError] = useState(false)
  
  const { language, dispatch } = useLanguageContext()

  let languageInfo;
  if (language === 'english') {
      languageInfo = english;
  } else if (language === 'spanish') {
      languageInfo = spanish;
  }


  // Get all text for Navbar component
  useEffect(()=> {
    const fetchData = async () => {
        const response = await fetch('https://opera-cultura-server.onrender.com/api/navbar')
        const json = await response.json()
        if (!response.ok) {
            setError(json.error)
        }
        if (response.ok) {
            setError(null)
            setNavbarInfo(json)
        }
      }
    fetchData()     
  }, [])


  const navigate = useNavigate();

  const location = useLocation();

  const handleClick = () => {
    setMenu(menu => !menu)
    setAboutMenu(false);
    setSeasonMenu(false);
    setInvolvedMenu(false);
    setCommunityMenu(false);
    setContactMenu(false);
  }

  const handleSubMenuClick = (e) => {
    e.stopPropagation()
    console.log("old:", e.target.id)
    if (e.target.id === "about" || e.target.id === "ab") {
      setAboutMenu(aboutMenu => !aboutMenu);
      setSeasonMenu(false);
      setInvolvedMenu(false);
      setCommunityMenu(false);
      setContactMenu(false);
    }
    else if (e.target.id === "season" || e.target.id === "se") {
      setAboutMenu(false);
      setSeasonMenu(seasonMenu => !seasonMenu);
      setInvolvedMenu(false);
      setCommunityMenu(false);
      setContactMenu(false);
    }
    else if (e.target.id === "involved" || e.target.id === "in") {
      setAboutMenu(false);
      setSeasonMenu(false);
      setInvolvedMenu(involvedMenu => !involvedMenu);
      setCommunityMenu(false);
      setContactMenu(false);
    }
    else if (e.target.id === "community" || e.target.id === "com") {
      setAboutMenu(false);
      setSeasonMenu(false);
      setInvolvedMenu(false);
      setCommunityMenu(communityMenu => !communityMenu);
      setContactMenu(false);
    }
    else if (e.target.id === "contact" || e.target.id === "con") {
      setAboutMenu(false);
      setSeasonMenu(false);
      setInvolvedMenu(false);
      setCommunityMenu(false);
      setContactMenu(contactMenu => !contactMenu);
    }
  }

  const handleLinkClick = () => {
    setAboutMenu(false);
    setSeasonMenu(false);
    setInvolvedMenu(false);
    setCommunityMenu(false);
    setContactMenu(false);
    setMenu(false);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 1236) {
  //       setWindowSmall(true);
  //     } else {
  //       setWindowSmall(false);
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const handleResize = () => {
    if (window.innerWidth < 910) {
      setWindowSmall(true);
    } else {
      setWindowSmall(false);
    }
  };

  useEffect(() => {
    // Call handleResize once after the component is mounted
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = (e) => {
    const id = e.currentTarget.id; // Use currentTarget instead of target
    if (windowSmall) {
      return
    } else if (id === "aboutLg") {
      setAboutMenu(true);
    } else if (id === "seasonLg") {
      setSeasonMenu(true);
    } else if (id === "involvedLg") {
      setInvolvedMenu(true);
    } else if (id === "communityLg") {
      setCommunityMenu(true);
    } else if (id === "contactLg") {
      setContactMenu(true);
    }
  };

  const handleMouseLeave = (e) => {
    const id = e.currentTarget.id; // Use currentTarget instead of target
    if (windowSmall) {
      return
    } else if (id === "aboutLg") {
      setAboutMenu(false);
    } else if (id === "seasonLg") {
      setSeasonMenu(false);
    } else if (id === "involvedLg") {
      setInvolvedMenu(false);
    } else if (id === "communityLg") {
      setCommunityMenu(false);
    } else if (id === "contactLg") {
      setContactMenu(false);
    }
  };

  const navHome = () => {
    navigate("/");
    setAboutMenu(false);
    setSeasonMenu(false);
    setInvolvedMenu(false);
    setCommunityMenu(false);
    setContactMenu(false);
    setMenu(false);
  }

  const toggleLanguage = () => {
    if (language === "english") {
      dispatch({type: 'SET_SPANISH'});
    } else {
      dispatch({type: 'SET_ENGLISH'})
    }
  }

  return (
    <header className="navbar__container">
      <div 
        className="navbar__logo-container">
          <img src={logo2} alt="logo" onClick={navHome} />
          <h1 className="navbar__logo-h1" onClick={navHome} >Ópera Cultura</h1>
      </div>
      {menu ? <IoMdClose className="navbar__icon" onClick={handleClick} /> : <CiMenuBurger className="navbar__icon" onClick={handleClick} />}    
      <nav>
        <ul className={`navbar__main-menu ${menu ? "active" : ""}`}>
          <li
            id="aboutLg"
            className="navbar__main-item" 
            onClick={handleSubMenuClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={aztecH} alt="Aztec Headdress" className="navbar__belt-up" />
            <p  
              id="about"
              className={
              location.pathname === "/mission" ||
              location.pathname === "/board" ||
              location.pathname === "/sponsors" ? "selected-about color-about" : "color-about"
            }
              >{navbarInfo && navbarInfo[0].aboutTitle}</p>
            <AiOutlineDown 
              id="ab"
              onClick={handleSubMenuClick}
              className="navbar__arrow color-about"
              style={{ transform: aboutMenu ? 'rotate(180deg)' : '' }} />
            {aboutMenu && (
              <ul className="sub-menu sub-menu-left" id="border-about">
                <li className="navbar__sub-item color-about">
                  <Link 
                    to="/mission" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].aboutMission}
                  </Link>
                </li>
                <li className="navbar__sub-item color-about">
                  <Link 
                    to="/board" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].aboutBoard}
                  </Link>
                </li>
                <li className="navbar__sub-item color-about">
                  <Link 
                    to="/sponsors" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].aboutSponsor}
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            id="seasonLg"
            className="navbar__main-item color-season"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleSubMenuClick} 
          >
            <img src={day} alt="Day of the Dead" className="navbar__belt-sm" />
            <p
              id="season"
              className={
                location.pathname === "/season" ||
                location.pathname === "/gallery" ? "selected-season color-season" : "color-season"
              }
              >{navbarInfo && navbarInfo[0].seasonTitle}</p>
            <AiOutlineDown 
              id="se"
              onClick={handleSubMenuClick}
              className="navbar__arrow color-season"
              style={{ transform: seasonMenu ? 'rotate(180deg)' : '' }} />
            {seasonMenu && (
              <ul className="sub-menu" id="border-season">
                <li className="navbar__sub-item color-season">
                  <Link 
                    to="/season" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].seasonSeason}
                  </Link>
                </li>
                <li className="navbar__sub-item color-season">
                  <Link 
                    to="/gallery" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].seasonGallery}
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li 
            id="involvedLg"
            className="navbar__main-item color-involved"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleSubMenuClick} 
            >
            <img src={mayan} alt="Mayan Sculpture" />
            <p
              id="involved"
              className={
              location.pathname === "/donations" ||
              location.pathname === "/volunteer" ||
              location.pathname === "/auditions" ? "selected-involved color-involved" : "color-involved"
            }
            >{navbarInfo && navbarInfo[0].involvedTitle}</p>
            <AiOutlineDown 
              id="in"
              onClick={handleSubMenuClick}
              className="navbar__arrow color-involved"
              style={{ transform: involvedMenu ? 'rotate(180deg)' : '' }} />
            {involvedMenu && (
              <ul className="sub-menu" id="border-involved">
                <li className="navbar__sub-item color-involved">
                  <Link 
                    to="/donations" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].involvedDonate}
                  </Link>
                </li>
                <li className="navbar__sub-item color-involved">
                  <Link 
                    to="/volunteer" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].involvedVolunteer}
                  </Link>
                </li>
                <li className="navbar__sub-item color-involved">
                  <Link 
                    to="/auditions" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].involvedAudition}
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            id="communityLg"
            className="navbar__main-item color-community"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleSubMenuClick} 
          >
            <img src={chichen} alt="Aztec Warrior" className="navbar__belt-sm" />
            <p 
              id="community"
              className={
              location.pathname === "/escuela" ||
              location.pathname === "/forums" ? "selected-community color-community" : "color-community"
              }
              >{navbarInfo && navbarInfo[0].communityTitle}</p>
            <AiOutlineDown 
              id="com"
              onClick={handleSubMenuClick}
              className="navbar__arrow color-community"
              style={{ transform: communityMenu ? 'rotate(180deg)' : '' }} />
            {communityMenu && (
              <ul className="sub-menu" id="border-community">
                <li className="navbar__sub-item color-community">
                  <Link 
                    to="/escuela" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].communityEscuela}
                  </Link>
                </li>
                <li className="navbar__sub-item color-community">
                  <Link 
                    to="/forums" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                  >
                    {navbarInfo && navbarInfo[0].communityForums}
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            id="contactLg"
            className="navbar__main-item color-contact"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleSubMenuClick} 
          >
            <img src={frida} alt="Frida Kahlo" className="navbar__belt-sm" />
            <p 
              id="contact"
              className={
              location.pathname === "/contact" ? "selected-contact color-contact" : "color-contact"
            }
            >{navbarInfo && navbarInfo[0].contactTitle}</p>
            {contactMenu && (
              <ul className="sub-menu sub-menu-right" id="border-contact">
                <li className="navbar__sub-item color-contact">
                  <Link 
                    to="/contact" 
                    onClick={(event) => {
                      event.stopPropagation();
                      handleLinkClick();
                    }}
                    >
                    {navbarInfo && navbarInfo[0].contactContact}
                  </Link>
                </li>
                <li className="navbar__sub-item color-contact" >
                  <a href="https://www.facebook.com/OperaCultura" target="_blank" rel="noopener noreferrer"><FaFacebook className="navbar__social-icon" /> Facebook</a>
                </li>
                <li className="navbar__sub-item color-contact">
                <a href="https://www.instagram.com/opera_cultura.latinx/" target="_blank" rel="noopener noreferrer"><FaInstagram className="navbar__social-icon" /> Instagram</a>
                </li>
                <li className="navbar__sub-item color-contact">
                <a href="https://www.youtube.com/@operacultura8841" target="_blank" rel="noopener noreferrer"><FaYoutube className="navbar__social-icon" /> YouTube</a>
                </li>
              </ul>
            )}
            <AiOutlineDown
              id="con"
              onClick={handleSubMenuClick}
              className="navbar__arrow color-contact"
              style={{ transform: contactMenu ? 'rotate(180deg)' : '' }} />
          </li>
        </ul>
      </nav>
      <div className='navbar__language-toggle' onClick={toggleLanguage}>
        {language === "english" ? <p>Español</p> : <p>English</p>}
      </div>
    </header>
  )
}

export default Navbar