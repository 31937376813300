import './banner.css'
import DOMPurify from 'dompurify';

const Banner = (props) => {
    const clean = DOMPurify.sanitize(props.title, {ALLOWED_ATTR: ['href', 'rel', 'target']})
    
    return (
        <div id='banner__container' style={{backgroundImage: `url(${props.image})`}}>
            <p dangerouslySetInnerHTML={{ __html: clean}}></p>
        </div>
    )
}

export default Banner