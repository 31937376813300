import './donations.css'
import { useState, useEffect } from 'react'
import Banner from '../../components/banner/Banner'
import d1 from '../../img/donate1.jpg'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

import DOMPurify from 'dompurify'

const Donations = () => {
    const [donateInfo, setDonateInfo] = useState(null)
    const [error, setError] = useState(null)
    const [donateParagraph, setDonateParagraph] = useState('')


    const { language } = useLanguageContext()

    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text for Donate page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/donate')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setDonateInfo(json)
            }
        }
            fetchData()     
    }, [])

         // For debugging, handling inner HTML, and images
    useEffect(()=> {
        console.log(donateInfo)
        if (donateInfo) {
            const cleanDonateParagraph = DOMPurify.sanitize(donateInfo[0].donateParagraph, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setDonateParagraph(cleanDonateParagraph)
        }
    }, [donateInfo])



     const redirectToLink = () => {
            window.open(`${donateInfo && donateInfo[0].donateButtonLink}`, "_blank");
    };


    return (
        <div className="donations__container">
            <Banner
                title={donateInfo && donateInfo[0].bannerText}
                image={donateInfo && donateInfo[0].bannerImage}
                />
            <div className="donations__grid">
            <div className="donations__content">
                    <h2>{donateInfo && donateInfo[0].donateTitle}</h2>
                    <p dangerouslySetInnerHTML={{__html: donateParagraph}}></p>
                    <button onClick={redirectToLink}>{donateInfo && donateInfo[0].donateButtonText}</button>
                    <p className="donations__tax-code">{donateInfo && donateInfo[0].taxCode}</p>
                </div>
                <img src={donateInfo && donateInfo[0].donateImage} alt={donateInfo && donateInfo[0].donateAltText} />
            </div>
        </div>
    )
}

export default Donations