import './donate.css'
import DOMPurify from 'dompurify';

const Donate = (props) => {
    const redirectToLink = () => {
        window.open(`${props.buttonLink}`, "_blank");
    };

    const clean = DOMPurify.sanitize(props.paragraph, {ALLOWED_ATTR: ['href', 'rel', 'target']})

    return (
        <div className='donate__container'>
            <p dangerouslySetInnerHTML={{ __html: clean}}></p>
            <button onClick={redirectToLink}>{props.buttonText}</button>
        </div>
    )
}

export default Donate