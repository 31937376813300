import { createContext, useReducer, useEffect } from "react";

export const LanguageContext = createContext()

export const languageReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ENGLISH':
            return {
                language: "english"
            }
        case 'SET_SPANISH':
            return {
                language: "spanish"
            }
        default:
            return state
    }
}

export const LanguageContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(languageReducer, {
      language: "english"
    });
  
    // Retrieve data from localStorage when the provider is initialized
    useEffect(() => {
        const storedLanguage = localStorage.getItem('urlLanguage');
        console.log(storedLanguage)
        if (storedLanguage) {
        //   const parsedLocation = JSON.parse(storedLocation);
          dispatch({ type: 'SET_LANGUAGE', payload: storedLanguage });
        }
      }, []);
  
    // Store data in localStorage when it changes
    // useEffect(() => {
    //   localStorage.setItem('urlLocation', state.location);
    // }, [state.location]);

    return (
        <LanguageContext.Provider value={{...state, dispatch}}>
            { children }
        </LanguageContext.Provider>
    )
}