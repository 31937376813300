import './escuela.css'
import React, { useEffect, useRef, useState } from 'react';

import Banner from '../../components/banner/Banner'
import e1 from '../../img/e1.jpg'
import escuela1 from '../../img/g29.jpg'
import escuela4 from '../../img/escuela4.jpg'
import escuela6 from '../../img/escuela6.jpg'
import escuela7 from '../../img/escuela7.jpg'
import escuela8 from '../../img/escuela8.jpg'
import escuela9 from '../../img/escuela9.jpg'

import { IoOpenOutline } from "react-icons/io5";

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'
import Donate from '../../components/donate/Donate'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

import DOMPurify from 'dompurify';

const Escuela = () => {
    const [escuelaForm, showEscuelaForm] = useState(false);
    const escuelaFormRef = useRef(null);
    const { language } = useLanguageContext()

    const [registrationOpen, setRegistrationOpen] = useState(false)

    const [escuelaInfo, setEscuelaInfo] = useState(null)
    const [error, setError] = useState(null)
    const [bannerImage, setBannerImage] = useState(null)
    const [openingParagraph, setOpeningParagraph] = useState(null)
    const [datesTimes, setDatesTimes] = useState(null)
    const [classDescription, setClassDescription] = useState(null)

    
    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text for Escuela page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/escuela')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setEscuelaInfo(json)
            }
        }
            fetchData()     
    }, [])

    // For debugging, handling inner HTML, and images
    useEffect(()=> {
        console.log(escuelaInfo)
        if (escuelaInfo) {
            const cleanOpeningParagraph = DOMPurify.sanitize(escuelaInfo[0].openingParagraph, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setOpeningParagraph(cleanOpeningParagraph)
            const cleanDatesTimes = DOMPurify.sanitize(escuelaInfo[0].datesTimes, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setDatesTimes(cleanDatesTimes)
            const cleanClassDescription = DOMPurify.sanitize(escuelaInfo[0].classDescription, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setClassDescription(cleanClassDescription)
            const cloudinaryBannerImage = escuelaInfo[0].bannerImage;
            setBannerImage(cloudinaryBannerImage)
        }
    }, [escuelaInfo])


    // I don't want them changing the button link (the google forms link)
    const redirectToGoogleForm = () => {
        window.open(`${languageInfo.escuela.buttonLink}`, "_blank");
    };

    // const handleClick = () => {
    //     showEscuelaForm(escuelaForm => !escuelaForm);
    // }
    const handleClick = () => {
        if (!escuelaForm) {
          showEscuelaForm(true);
          setTimeout(() => {
            if (escuelaFormRef.current) {
              window.scrollTo({
                top: escuelaFormRef.current.offsetTop,
                behavior: 'smooth',
              });
            }
          }, 0);
        } else {
          showEscuelaForm(false);
        }
      };



    // Set signup button if registration is open
    useEffect(() => {
        if (escuelaInfo) {
          const currentDate = new Date();
          const registrationEndDate = new Date(escuelaInfo[0].endDate); // Assuming endDate is a valid date string in the format "YYYY-MM-DD"
          const registrationStartDate = new Date(escuelaInfo[0].startDate); // Assuming endDate is a valid date string in the format "YYYY-MM-DD"
      
          console.log("current date", currentDate)
          console.log("end date:", registrationEndDate)
          if (currentDate >= registrationEndDate || currentDate <= registrationStartDate) {
            // Registration has ended or hasn't started
            // ^^^ This is off by like 5 and a 1/2 hours...
            setRegistrationOpen(false)
          } else {
            // Registration is still open
            setRegistrationOpen(true)
          }
        }
      }, [escuelaInfo]);


    return (
        <div className="escuela__container">
           <Banner
                title={escuelaInfo && escuelaInfo[0].bannerText}
                image={escuelaInfo && escuelaInfo[0].bannerImage}
                />


            <p className="escuela__description2" dangerouslySetInnerHTML={{__html: openingParagraph}}></p>
            {/* <h2>{languageInfo.escuela.title}</h2> */}
            <div className="escuela__grid">
                <img src={escuelaInfo && escuelaInfo[0].openingImage} alt={escuelaInfo && escuelaInfo[0].openingImageAltText} /> 
                <div>
                    <h3>{languageInfo.escuela.schoolYear}</h3>
                    <p dangerouslySetInnerHTML={{__html: datesTimes}}></p>
                    <p><span>{escuelaInfo && escuelaInfo[0].locationSpan}: </span><a href={escuelaInfo && escuelaInfo[0].locationLink} target="_blank" rel="noopener noreferrer" >
                    {escuelaInfo && escuelaInfo[0].locationName} <IoOpenOutline className="escuela__map-icon" />
                    </a></p>
                    <p dangerouslySetInnerHTML={{__html: classDescription}}></p>
                    <p className='escuela__registration'>{escuelaInfo && escuelaInfo[0].registrationSentance}</p>
                    {registrationOpen && <button className="escuela__googleBTN" onClick={handleClick}>{escuelaInfo && escuelaInfo[0].signupButton}</button>}
                </div>
            </div>
            {escuelaForm &&
                <div 
                    className="escuela__googleForm"
                    id='escuelaForm'
                    ref={escuelaFormRef}>
                {language === "english" && <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdeqezTFhrMqEoYQGpBcrhinHf1kMlbhfkXrJTChWxT151bdQ/viewform?embedded=true">Loading…</iframe> }
                {language === "spanish" && <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc6MbRIMvplbme5mi-pZxYPTlRuJvLwx5mKEnB9O_HQyZURFA/viewform?embedded=true">Loading…</iframe>}
                </div>
            }
            <Donate
                paragraph={escuelaInfo && escuelaInfo[0].donationsBlurb}
                buttonText={escuelaInfo && escuelaInfo[0].donationsButtonText}
                buttonLink={escuelaInfo && escuelaInfo[0].donationsButtonLink}
            />
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
            {escuelaInfo && escuelaInfo[0].imageCarousel.map((image) => (
                <SwiperSlide>
                    <img src={image} alt="La Escuela image" />
                </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default Escuela