import './auditions.css'
import { useState, useEffect, useRef } from 'react'

import Banner from '../../components/banner/Banner'

import peraza from '../../img/g21.jpg'

import {english} from '../../info/english'
import {spanish} from '../../info/spanish'
import { useLanguageContext } from '../../hooks/useLanguageContext'

import DOMPurify from 'dompurify'

const Auditions = () => {
    const { language } = useLanguageContext()

    const [auditionInfo, setAuditionInfo] = useState(null)
    const [error, setError] = useState(null)
    const [auditionParagraph, setAuditionParagraph] = useState('')
    
    let languageInfo;
    if (language === 'english') {
        languageInfo = english;
    } else if (language === 'spanish') {
        languageInfo = spanish;
    }


    // Get all text for Audition page
    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch('https://opera-cultura-server.onrender.com/api/audition')
            const json = await response.json()
            if (!response.ok) {
                setError(json.error)
            }
            if (response.ok) {
                setError(null)
                setAuditionInfo(json)
            }
          }
        fetchData()     
      }, [])

    // For debugging, handling inner HTML, and images
    useEffect(()=> {
        console.log(auditionInfo)
        if (auditionInfo) {
            const cleanAuditionParagraph = DOMPurify.sanitize(auditionInfo[0].auditionParagraph, {ALLOWED_ATTR: ['href', 'rel', 'target']})
            setAuditionParagraph(cleanAuditionParagraph)
        }
    }, [auditionInfo])
    
    return (
        <div className="auditions__container">
            <Banner
                title={auditionInfo && auditionInfo[0].bannerText}
                image={auditionInfo && auditionInfo[0].bannerImage}
            />
            <h1 className="auditions__title">{auditionInfo && auditionInfo[0].auditionTitle}</h1>
            <div className="auditions__flex">

                <div>
                    <p 
                        dangerouslySetInnerHTML={{__html: auditionParagraph}}
                        className="auditions__paragraph">
                    </p>
                </div>
                <img src={auditionInfo && auditionInfo[0].auditionImage} alt={auditionInfo && auditionInfo[0].auditionAltText} />
            </div>
        </div>
    )
}

export default Auditions