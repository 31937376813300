import cantar from '../img/cantar2.jpg'
import llorona from '../img/llorona-poster.png'
import lloronaAd from '../img/llorona-poster2.png'
import lloronaSquare from '../img/lloronaSquare.jpg'
import alejandro from '../img/alejandro.webp'
import bailey from '../img/bailey.jpg'
import sanchez from '../img/sanchez.jpg'
import yerzhanov from '../img/Yerzhanov.jpg'

import cantarPromo from '../movies/Omar_Alejandro_reel.mp4'
import lloronaPromo from '../movies/Llorona_promo.mp4'

import mexicanHeritage from '../img/mexican-heritage-map.png'
import trinity from '../img/map-trinity.png'

export const english = {
    navbar: {
        about: {
            title: "About",
            submenu: {
                title1: "Mission and History",
                title2: "Board and Staff",
                title3: "Sponsors"
            }
        },
        season: {
            title: "Season",
            submenu: {
                title1: "23 / 24 Season",
                title2: "Gallery"
            }
        },
        involved: {
            title: "Get Involved",
            submenu: {
                title1: "Donate",
                title2: "Volunteer",
                title3: "Auditions"
            }
        },
        community: {
            title: "Community",
            submenu: {
                title1: "La Escuela",
                title2: "Behind the Scenes"
            }
        },
        contact: {
            title: "Contact Us",
            submenu: {
                title1: "Contact Us",
            }
        }
    },
    footer: {
        title1: "Mailing Address",
        title2: "Contact",
        title3: "Social",
        contact1: "Need additional assistance?",
        contact2: "Please contact us:"
    },
    landing: {
        hero: {
            title: "Ópera Cultura",
            tagline: "Building a bridge for Latino/x/a/é artists and communities"
        },
        popup: {
            text: "Bring in the New Year at our holiday sing along event, 'Cantar con los Reyes' on January 6th!",
            buttonLink: "/cantar-con-los-reyes",
            buttonText: "Tickets"
        },
        cta: {
            titleBefore: "Donate to ",
            titleBold: "Ópera Cultura",
            titleAfter:"",
            paragraph:"Join us in our mission in giving voice to the Latino/x/a/é community through opera and musical drama. 100% of your tax deductible gift will be used to support Ópera Cultura’s programming, artists, and education initiatives.  100% of your tax-deductible gift will be used to support Ópera Cultura's programming, artists, and 'La Escuela' education initiatives.",
            buttonText:"Join Us",
            buttonLink:"https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU"
        },
        upcomingEvents: {
            title: "Upcoming events",
            option1: {
                image: cantar,
                title:"Cantar con los Reyes",
                date:"January 6th 2024",
                buttonText: "More Info",
                buttonLink: "/cantar-con-los-reyes"
            },
            option2: {
                image:lloronaSquare,
                title:"La Llorona",
                date:"June 22nd, June 23rd 2024",
                buttonText: "More Info",
                buttonLink: "/la-llorona"
            }
        },
        mailingList: {
            title: "Join our mailing list",
            fName: "First Name",
            lName: "Last Name",
            email: "Email",
            buttonText: "Join",
            buttonLink: ""
        }
    },
    mission: {
        banner1: "About ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        bannerColor: 'linear-gradient(#E9999A, #E73437)',
        cta: "Ópera Cultura relies on the support of our community in order to flourish. No matter the amount, your support is deeply appreciated.",
        ctaButtonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        ctaButtonText: "Donate Now",
        mission: {
            title: "Mission",
            content: "Our mission is to give “VOICE” to the Latino/x/a/é community through opera, while providing opportunities for the San José community to participate as creators, learners, and performers. We accomplish this by presenting the work of composer Héctor Armienta and other Hispanic composers, showcasing exceptional artists, and training young people in the performing arts. By doing so, we create a cultural bridge between communities."
        },
        history: {
            title: "History",
            content: "Our programming in the past has included presenting Latino/x/a/é opera, collaborating with Latino/x/a/é based arts organizations, and providing training for young people in the performing arts through La Escuela Initiative. While San José remains our artistic home, we have also presented and co-presented in New York, Washington D.C, and México. Notable performances include La LLorona, Bless Me Ultima, and Armienta’s immigration song cycle opera Cuentos de Peregrinacíon. In 2021, we produced Mi Camino – an animated film opera that was told in the virtual world. Based on video interviews of farmworkers, it told the story of farm workers who have been impacted by the Covid 19 pandemic. In 2022, we produced Robert Rodriguez’s opera Frida at the Mexican Heritage Theater – School of Arts and Culture. The opera is based on the life of Mexican visual artist Frida Kahlo. Most recently, we produced composer Nicolás Benavides’opera Tres Minutos."
        }
    },
    board: {
        banner1: "About ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        bannerColor: 'linear-gradient(#E9999A, #E73437)',
        staff: {
            title: "Staff",
            staff1: {
                title: "Héctor Armienta",
                position: "Artistic Director & Founder",
                bio1: "Mr. Armienta oversees artistic programming for the company. He also works closely with Artistic Producer Sandra Bengochea and the board on long term planning and La Escuela Initiative.",
                bio2: "As a composer, he has been commissioned and performed by several opera companies, including Fort Worth Opera, Opera Southwest, Portland Opera, and others. His most recent opera Zorro, has been produced in Texas, New Mexico, and soon in California."
            },
            staff2: {
                title: "Sandra Raquel Bengochea",
                position: "Executive Director",
                bio1: "Described in Opera News as having “natural vivacity and charisma that resulted in a performance that was a joy from the first note to the last”, Sandra Raquel Rubalcava has earned rave reviews for her spirited interpretations of the soprano repertoire.  She has performed over forty operatic roles, ranging from the comic ones of Susanna in The Marriage of Figaro, to the dramatic ones of Mimi in La Bohème and the title role in Manon with companies around the world such as Bangkok Opera and Tel Aviv Opera to local companies such as  Intermountain Opera, and Opera Idaho.",
                bio2: "Equally comfortable on the concert and recital stage, Sandra has performed the soprano solos with orchestras such as the San José Symphony, The Sacramento Chorale Society and The Midsummer Mozart Festival, where she appeared as a last-minute replacement for Frederica Von Stade.",
                bio3: "As a stage director Ms Rubalcava has received two National Opera Association Awards for her operatic productions of Bernstein’s Mass and double bill of La Curandera and Trial by Jury. Still an active performer, Sandra nationally premiered the title role in Dulcinea by Mauricio Sotelo, performed the role of Aldonza in Man of la Mancha and was the soprano soloist in Mozart’s Requiem with I Cantori di Carmel.",
                bio4: "A first generation immigrant and proud advocate of her heritage, Sandra is the Executive Director of Ópera Cultura where she most recently produced Très Minutos by Nicolas Lell Benavides.  She serves on the faculty at Stanislaus State University and San José State University.  Sandra enjoys an active career as voice instructor, stage director, producer, autism advocate and busy mommy to two extremely active and exceptional boys."
            }
        },
        boardTitle: "Board of Directors"
    },
    sponsors: {
        banner1: "About ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        bannerColor: 'linear-gradient(#E9999A, #E73437)',
        title: "Sponsors",
        p1: "Give “Voice” to the Latino/x/a/é community through opera by donating today! Your support allows us to:",
        item1: "Present work about the Latino/x/a/é community",
        item2: "Be an advocate for Latino/x/a/é artists and creators in the field of opera",
        item3: "Provide training to young people in the performing arts",
        item4: "Present workshops in the community",
        p2: "As a nonprofit organization, Ópera Cultura relies on the generosity of your donations to sustain their productions. Any contributions are highly valued and appreciated!",
        buttonText: "Donate Now",
        buttonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        sponsors: "Our Sponsors"
    },
    season: {
        banner1: "",
        bannerBold: "Ópera Cultura",
        banner2: " 23 / 24 Season",
        bannerColor: "linear-gradient(#FCFDCD, #D5D900)",
        donate: {
            paragraph: "Can't attend a show but still want to support?",
            buttonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
            buttonText: "Donate Now"
        },
        event1: {
            title: "Cantar con los Reyes",
            subtitle: "Holiday Community Event",
            dates: ["Saturday, January 6th at 2pm"],
            image: cantar,
            location: {
                googleMapsLink: "https://www.google.com/maps/dir//3151+Union+Ave,+San+Jose,+CA+95124/@37.2646706,-121.9390069,14.81z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808e34593d4d6dad:0xcf66768d074be8b2!2m2!1d-121.9325501!2d37.2660867?entry=ttu",
                mapImage: trinity,
                locationName: "Trinity Presbyterian Church",
                address1: "3151 Union Ave",
                address2: "San Jose, CA 95124"
            },
            tag: "Bring the family to our Holiday sing along event!",
            content: "Come celebrate the season on January 6th with joyful music and shared traditions at our holiday sing along event. Guest artist, Omar Alejandro, baritone will lead the fiesta with familiar holiday tunes and canciones from ”La Epoca de Oro” made famous by artists such as Los Panchos, Jorge Negrete, and Pedro infante.",
            buttonLink: "https://www.tix.com/ticket-sales/operacultura/6882/event/1353852",
            buttonText: "Tickets",
            noButtonText: "",
            video: cantarPromo,
            featuredArtistTitle: "Featured Artists",
            featuredArtists: [
                {
                name: "Omar Alejandro",
                image: alejandro,
                description: "With his operatic singing style and gallant stage presence, Omar Alejandro breathes new life into the mariachi and operatic genres. The California native is quickly establishing himself as one of the region's most versatile solo singers; performing classics from the golden age of Mexican cinema, opera, musical theater, and everything in between.",
                buttonText: "Omar Alejandro",
                buttonLink: "https://www.omaralejandrorodriguez.com/"
                }
            ]
        },
        event2: {
            title: "La Llorona",
            subtitle: "A Musical Drama",
            dates: [
                "June 22nd at 7:30pm",
                "June 23rd at 2:00pm"
            ],
            image: lloronaSquare,
            imageAd: "",
            location: {
                googleMapsLink: "https://www.google.com/maps/place/Mexican+Heritage+Plaza/@37.3496823,-121.8626666,14.78z/data=!3m1!5s0x808fcd1fae008e3b:0xf2abd411adb336f0!4m6!3m5!1s0x808fcd1e39ee0cc1:0x7a2c29050d3dcecf!8m2!3d37.3528342!4d-121.8547252!16zL20vMDUyOXd0?entry=ttu",
                mapImage: mexicanHeritage,
                locationName: "Mexican Heritage Plaza",
                address1: "1700 Alum Rock Ave",
                address2: "San Jose, CA 95116"
            },
            tag: "Rediscover the ancient tale of the Weeping Woman. Composed and written by Héctor Armienta.",
            content: "Héctor Armienta’s La Llorona returns to San Jose in June 2024. Seen in California and New York, past productions have sold out to this award winning musical drama.",
            buttonLink: "https://www.tix.com/ticket-sales/operacultura/6882",
            buttonText: "Tickets",
            noButtonText: "",
            video: lloronaPromo,
            featuredArtistTitle: "Featured Artists",
            featuredArtists: [
                {
                name: "Stephanie Sanchez",
                image: sanchez,
                description: "In the 2022/23 season, dynamic mezzo-soprano Stephanie Sánchez brought her 'luscious sound and easy technique' (Classical Voice North America) to companies including Arizona Opera, Intermountain Opera Bozeman, Opera Southwest, and The Lyric Opera of Chicago, with whom she performed roles in both Proximity: The Walkers and Proximity: Four Portraits. She began the 2023/24 season with Cincinnati Opera, singing Chief Officer's Wife in The Knock. She looks forward to her role debut as Die Hexe in Hänsel und Gretel with Kentucky Opera, and she then debuts with Opera Omaha as the titular Frida Kahlo in El ultimo sueño de Frida y Diego. In summer 2024, she joins West Edge Opera as Flosshilde/Fricka/Erda in Der Ring in einem Abend.",
                buttonText: "Stephanie Sanchez",
                buttonLink: "https://www.stephaniesanchezmezzosoprano.com/"
                },
                {
                name: "Allie Bailey",
                image: bailey,
                description: "",
                buttonText: "Allie Bailey",
                buttonLink: "https://www.linkedin.com/in/allie-bailey-9662b396/"
                },
                {
                name: "Temirzhan Yerzhanov",
                image: yerzhanov,
                description: "",
                buttonText: "Temirzhan Yerzhanov",
                buttonLink: "https://spotlightartistsmanagement.com/artists/temirzhan-yerzhanov/"
                },
            ]
        }
    },
    gallery: {
        banner1: "",
        bannerBold: "Ópera Cultura",
        banner2: " Gallery",
        bannerColor: "linear-gradient(#FCFDCD, #D5D900)",
    },
    donations: {
        banner1: "Get Involved with ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        bannerColor: "linear-gradient(#A3FFD8, #209600)",
        title: "Donations",
        p1: "At Ópera Cultura, we believe that opera is a vibrant and transformative art form that deserves to be shared with everyone. However, mounting a performance can be quite expensive, and ticket sales only cover a portion of the costs. That's why we rely on the generous support of individuals, foundations, and corporations to help us bring the magic of opera to life. By donating to Ópera Cultura, you become an important part of our mission to provide exceptional operatic experiences and valuable educational programs to our San José community.",
        p2: "Together, let's move opera forward and sustain the artistic excellence and community partnerships that Ópera Cultura provides. No matter the amount, your support is deeply appreciated. Join us in embracing the power of 'apoyo' and become a part of our vibrant Latino/x/a/é opera familia today.",
        p3: "Ópera Cultura accepts donations online, via check, stock, and bequests. Please reach out to our Executive Director, Sandra Raquel Bengochea, at s.bengochea@operacultura.org to learn more about ways to give.",
        buttonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        buttonText: "Donate Now",
        taxes: "Opera Cultura is a 501(c)(3) nonprofit, EIN #46-4332937. All donations are tax-deductible."
    },
    volunteer: {
        banner1: "Get Involved with ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        bannerColor: "linear-gradient(#A3FFD8, #209600)",
        title: "Be part of the Ópera Cultura familia!",
        p1: "We always welcome volunteers to join our vibrant opera community and contribute to our productions! There are many exciting ways to get involved and make a difference, such as ushering, running concessions, helping backstage, hospitality, education, etc.",
        p2: "No matter which role you choose, volunteering with us is a rewarding experience that allows you to be part of the opera magic and contribute to our mission of bringing exceptional performances to our San José community. Join us as a volunteer and help us create unforgettable moments together!",
        buttonLink: "",
        buttonText: "Volunteer"
    },
    escuela: {
        banner1: "",
        bannerBold: "Ópera Cultura",
        banner2: " Community",
        bannerColor: "linear-gradient(#CDFAF4, #00F1CF)",
        cta: "La Escuela cannot exist without the support of our community. No matter the amount, your support is deeply appreciated.",
        ctaButtonLink: "https://www.paypal.com/donate/?hosted_button_id=XFLE4G39QRMSU&fbclid=IwAR0twCSxpqc19QJ_T-udIVhOYN0wCFmJbHWSRM2GnZSTypdgkTcsqXhsHwU",
        ctaButtonText: "Be a Supporter",
        title: "La Escuela Initiative",
        content: "Established in 2022, La Escuela provides training in the performing arts to young people ages 16 – 22. After participating in a 10 week program, some of the students have the opportunity to perform in our main stage productions alongside professional artists. Each student receives a stipend for their participation. Interested students and parents please contact us at info@operacultura.org for more information.",
        buttonLink: "https://forms.gle/VaCpJzrFaqwEeTXC9",
        buttonText: "Sign Up",
        schoolYear: "2023 / 2024 School Program",
        schoolDay: "Tuesdays",
        schoolDates: "4/16, 4/30, 5/14, 5/28",
        schoolTime: "Time",
        schoolHours: "6:30pm - 8:30pm",
        schoolLocation: "Location",
        schoolPlace: "Hoover Theater",
        schoolMapLink: "https://www.google.com/maps/place/Historic+Hoover+Theatre/@37.3327678,-121.9258175,17z/data=!3m1!4b1!4m6!3m5!1s0x808fcb6b1a112e3f:0x7b8a30e2b55de789!8m2!3d37.3327678!4d-121.9232426!16s%2Fg%2F1hhx3zgj1?entry=ttu",
        schoolDescription: "This year’s school program will be a combined music and theater workshop focusing on folklore, specifically Mexican folklore and the tale of 'La Llorona'.",
        schoolRegistration: "Registration opens January 15th and closes March 31st"

    },
    contact: {
        banner1: "Contact ",
        bannerBold: "Ópera Cultura",
        banner2: "",
        bannerColor: "linear-gradient(#FDA4E2, #FA17B6)",
        title: "Contact Us",
        name: "Name",
        email: "Email",
        message: "Message",
        buttonLink: "",
        buttonText: "Submit"
    }
}
